import React from 'react'

const Deserts = () => {
  return (
    <div>
        <h1>Deserts</h1>
    </div>
  )
}

export default Deserts;